<template>
  <div>
    <Header />
    <!-- ======= Hero Section ======= -->
    <section id="hero">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center"
            data-aos="fade-right"
          >
            <div class="home-title">
              <h1 class="title">
                <span style="color: #8dc540">
                  <div>
                    <span class="app-name">{{ $t("appName") }}:</span>
                    {{ $t("OneStopSolution") }}
                  </div>
                  <div>{{ $t("ForEasyAnd") }}</div>
                  <div>{{ $t("SecureP2PTrading") }}</div></span
                >
              </h1>

              <a :href="dynamicUrlLogin" class="login-btn scrollto">{{
                $t("LetsStart")
              }}</a>
            </div>
          </div>
          <div
            class="col-lg-6 order-1 order-lg-2 hero-img"
            data-aos="fade-left"
          >
            <img src="assets/img/hero-img.svg" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
    <!-- End Hero -->
    <div class="container">
      <div class="tab-slider--nav">
        <ul class="tab-slider--tabs">
          <li
            @click="changeTab(1)"
            :class="isActive === 1 ? 'active' : ''"
            class="tab-slider--trigger buy1 active"
            rel="tab1"
          >
            {{ $t("Buy") }}
          </li>
          <li
            @click="changeTab(2)"
            :class="isActive === 2 ? 'active' : ''"
            class="tab-slider--trigger sell1"
            rel="tab2"
          >
            {{ $t("Sell") }}
          </li>
        </ul>
      </div>
      <div
        v-if="isActive === 1"
        class="tab-slider--container"
        data-aos="zoom-in"
      >
        <div id="tab1" class="tab-slider--body">
          <h2 class="sectio-title">{{ $t("HowP2PWorksWithPikeswop?") }}</h2>

          <!-- ======= First Section ======= -->
          <section class="services" data-aos="zoom-in">
            <div class="">
              <div class="cards">
                <div class="card card-img">
                  <img src="assets/img/Icon-1.svg" alt="" />
                  <h3 class="card-title">{{ $t("SubmitAnOrder") }}</h3>
                  <p class="card-text">
                    {{ $t("SubmitAnOrderSubtitle") }}
                  </p>
                </div>
                <div class="card card-img">
                  <img src="assets/img/Group 1000002820.svg" alt="" />
                  <h3 class="card-title">{{ $t("PayToTheSeller") }}</h3>
                  <p class="card-text">
                    {{ $t("PayToTheSellerSubtitle") }}
                  </p>
                </div>
                <div class="card card-img">
                  <img src="assets/img/Icon-3.svg" alt="" />
                  <h3 class="card-title">{{ $t("ReceiveYourCrypto") }}</h3>
                  <p class="card-text">
                    {{ $t("ReceiveYourCryptoSubtitle") }}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <!-- ======= First Section End ======= -->
        </div>
      </div>

      <div
        v-if="isActive === 2"
        class="tab-slider--container"
        data-aos="zoom-in"
      >
        <div id="tab2" class="tab-slider--body">
          <h2 class="sectio-title">{{ $t("p2p-work") }}</h2>

          <!-- ======= First Section ======= -->
          <section class="services" data-aos="zoom-in">
            <div class="">
              <div class="cards">
                <div class="card card-img">
                  <img src="assets/img/Icon-1.svg" alt="" />
                  <h3 class="card-title">{{ $t("SubmitAnOrder") }}</h3>
                  <p class="card-text">
                    {{ $t("SubmitAnOrderSubtitlesell") }}
                  </p>
                </div>
                <div class="card card-img">
                  <img src="assets/img/Group 1000002820.svg" alt="" />
                  <h3 class="card-title">{{ $t("PayToTheSellersell") }}</h3>
                  <p class="card-text">
                    {{ $t("PayToTheSellerSubtitlesell") }}
                  </p>
                </div>
                <div class="card card-img">
                  <img src="assets/img/Icon-3.svg" alt="" />
                  <h3 class="card-title">{{ $t("ReceiveYourCryptosell") }}</h3>
                  <p class="card-text">
                    {{ $t("ReceiveYourCryptoSubtitle") }}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <!-- ======= First Section End ======= -->
        </div>
      </div>
    </div>
    <!-- ======= Second Section ======= -->
    <h2 class="sectio-title zero-top bottom-50">{{ $t("EasyEnrolling") }}</h2>
    <section class="services bottom-cust" data-aos="zoom-in" id="hero">
      <div class="container">
        <div class="cards cards1">
          <div class="card-2">
            <img src="assets/img/box-image.svg" class="img-fluid" />
          </div>
          <div class="card-2">
            <div id="ellipse_1800">{{ $t("1") }}</div>
            <h3 class="card-title">{{ $t("Register") }}</h3>
            <p class="card-text">{{ $t("RegisterWithPikeswop") }}</p>
          </div>
          <div class="card-2">
            <div id="ellipse_1800">{{ $t("2") }}</div>
            <h3 class="card-title">{{ $t("GetVerified") }}</h3>
            <p class="card-text">{{ $t("CompleteTheVerificationProcess") }}</p>
          </div>
          <div class="card-2">
            <div id="ellipse_1800">{{ $t("3") }}</div>
            <h3 class="card-title">{{ $t("LetsStartTrading") }}</h3>
            <p class="card-text">
              {{ $t("YouAreAllSetForP2PTrading") }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- ======= Second Section End======= -->
    <div class="section-title" data-aos="fade-up">
      <h2 class="sectio-title">
        {{ $t("TradeConfidentlyTradeWithPikeswop") }}
        <br />
        {{ $t("meetsOpportunity!") }}
      </h2>
    </div>

    <!-- ======= Third Section ======= -->
    <section class="services zero-top-padding" data-aos="zoom-in">
      <div class="container">
        <div class="cards cards3">
          <div class="card-3">
            <img src="assets/img/stoarage-image.svg" lass="img-fluid" />

            <h3 class="card-title">{{ $t("SecureAssetStorage") }}</h3>
            <p class="card-text">
              {{ $t("PikeswopIndustry") }}
              <br />
              {{ $t("encryptionAndStorageSystems") }}

              {{ $t("are") }}
              <br />{{ $t("alwaysSafeAndSecure") }}
            </p>
          </div>
          <div class="card-3">
            <img src="assets/img/security-image.svg" lass="img-fluid" />
            <h3 class="card-title">{{ $t("RobustAccountSecurity") }}</h3>
            <p class="card-text">
              {{ $t("WeAdhereToTheHighestSecurity") }}
              <br />
              {{ $t("standardsAndImplementThe") }}
              <br />
              {{ $t("strictestSecurityPracticesTo") }}
              <br />
              {{ $t("keepYourAccountSecure") }}
            </p>
          </div>
          <div class="card-3">
            <img src="assets/img/platform-image.svg" lass="img-fluid" />
            <h3 class="card-title">{{ $t("TrustedPlatform") }}</h3>
            <p class="card-text">
              {{ $t("WeHaveASecure-by-design") }}
              <br />
              {{ $t("foundationInPlaceToEnsure") }}
              <br />
              {{ $t("rapidDetectionAndResponseTo") }}
              <br />
            </p>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import {
  BLink,
  BButton,
  BImg,
  BNavbarNav,
  BNavbarToggle,
  BNavbar,
  BCollapse,
  BRow,
  BCol,
  BCard,
  BCardText
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";
import Locale from "@/layouts/components/Locale.vue";
import Header from "./../Header.vue";
import Footer from "./../Footer.vue";
import Contact from "@/assets/images/pages/Contact.svg";
import Logo from "@/assets/rashiCoin/logo.svg";
export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    BNavbarNav,
    BNavbarToggle,
    BNavbar,
    BCollapse,
    Locale,
    BRow,
    BCol,
    BCard,
    BCardText,
    Contact,
    Logo,
    Header,
    Footer
  },
  data() {
    return {
      isActive: 1,
      lang: this.$route.params.lang,
      dynamicUrlLogin: "https://dev.pikeswop.com/auth/login",
      dynamicUrlRegister: "https://dev.pikeswop.com/auth/register"
    };
  },
  mounted() {
    if (process.env.VUE_APP_NODE_ENV === "production") {
      this.dynamicUrlLogin = "https://app.pikeswop.com/auth/login";
      this.dynamicUrlRegister = "https://app.pikeswop.com/auth/register";
    } else {
      this.dynamicUrlLogin = "https://dev.pikeswop.com/auth/login";
      this.dynamicUrlRegister = "https://dev.pikeswop.com/auth/register";
    }
  },
  methods: {
    changeTab(tab) {
      this.isActive = tab;

      if (tab === 2) {
        // this.handleTabChange(2);
        document.querySelector(".tab-slider--tabs").classList.add("slide");
      } else {
        // this.handleTabChange(1);
        document.querySelector(".tab-slider--tabs").classList.remove("slide");
      }
    },
    openPage(props) {
      this.$router.push({
        name: props,
        params: { lang: this.lang || undefined }
      });
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.downImg;
      }
      return this.downImg;
    }
  }
};
</script>

<style lang="scss">
// @import "../../assets/landing/vendor/aos/aos.scss";

@import "@core/scss/vue/pages/page-misc.scss";
</style>
<style lang="scss" scoped>
.tab-slider--nav {
  float: left;
}

.tab-slider--tabs {
  display: block;
  float: right;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
  border: 2px solid #8dc540;
  height: 44px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tab-slider--tabs:after {
  content: "";
  width: 50%;
  background: #8dc540;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 250ms ease-in-out;
  border-radius: 5px;
  border: 2px solid white;
}

.tab-slider--tabs.slide:after {
  left: 50%;
  background-color: #f85663 !important;
}

.tab-slider--tabs.slide:after .buy1 {
  color: #060505 !important;
}

.tab-slider--tabs.slide {
  border: 2px solid #f85663;
}

.tab-slider--trigger {
  font-size: 12px;
  font-weight: bold;
  color: #345f90;
  text-transform: uppercase;
  text-align: center;
  padding: 11px 20px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  display: inline-block;
  transition: color 250ms ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 120px;
}

.tab-slider--trigger .active {
  color: #fff;
}

.slide .sell1 {
  color: #fff;
}

.tab-slider--tabs .buy1 {
  color: #fff;
}

.slide .buy1 {
  color: #345f90;
}

.tab-slider--body {
  margin-bottom: 20px;
}

.tab-border-color {
  border-bottom: 2px solid red;
}
.zero-top {
  margin-top: 0px;
}
// .zero-top.contact {
//   padding-top: 0px !important;
// }
.app-name {
  color: #0a0909;
}

.ForSupportQueriesEmail {
  display: flex;
  margin-left: 5px;
  align-items: center;
  &:hover {
    color: #8dc540 !important;
  }
}
.contact-svg svg {
  width: 100%;
  max-width: 100%;
}
</style>
